import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

import { sliderSettings } from './constants';

import GoogleReviewLogo from "../../Assets/images/google-review-logo.png";

export const Testimonials = (props) => {
  const { items, title = 'What they say about us' } = props;

  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
  };

  let loopItems = items;

  if (items && items.length <= 3) {
    additionalSliderSettings.initialSlide = 1;
    loopItems = [...items, ...items];
  }

  return (
    <section className="home-testimonials">
      <div className="container">
        <div className="sub-title">Testimonials</div>
        <h2>{title}</h2>
        <img className="google-img" src={GoogleReviewLogo} alt="Google Review Logo" />
        <div className="testimonial-slider">
          <Slider {...sliderSettings} {...additionalSliderSettings}>
            {loopItems.map((item, index) => (
              <div className="box" data-number={`0${index + 1}`} key={index}>
                <p>{item.text}</p>
                <div className='testimonials-user'>
                  {
                    item.photo && <img className='testimonials-photo' src={item.photo} alt="Customer who've left review" />
                  }
                  <div className='testimonials-info'>
                    <div className="name">{item.name}</div>
                    <div className="rating">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          onClick={() => { window.open("https://www.google.com/search?q=agm+renovations+google+reviews&oq=agm+renovations+google+reviews&aqs=chrome..69i57j0l4j69i60l3.3523j0j4&sourceid=chrome&ie=UTF-8#lrd=0x882b2dd8ee9962bd:0xd03d1da943a37695,1", "blank") }}
          style={{ cursor: 'pointer', userSelect: 'none', zIndex: '10000', position: 'relative' }}
          className="blue-btn"
        >More Testimonials</div>
      </div>
    </section>
  )
}