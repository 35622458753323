import React from "react";
import { Modal } from "../../Common/Modal";
import closeBtn from "../../Assets/images/close-btn.svg";
import "./personalmodal.scss";

const PersonModal = ({ open, onClose, person, children }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <span className="agentWrapper">
        <img className="closeBtn" src={closeBtn} onClick={() => onClose} />
        <h2 style={{ width: "100%", textAlign: "center" }}>{person.name}</h2>
        <div className="wrapper">
          <div className="personContainer">
            {children}
            <div className="propertie">
              <p>Name :</p>
              <p>{person.name}</p>
            </div>
            <div className="propertie">
              <p>Brokerage Name :</p>
              <p>{person.brokerageName}</p>
            </div>
            <div className="propertie">
              <p>Email : </p>
              <p>{person.email}</p>
            </div>
            <div className="propertie">
              <p>Phone : </p>
              <p>{person.phone}</p>
            </div>
          </div>
          <div className="infoContainer">
            <div className="propertie">
              <p>Agent Type :</p>
              <p>{person.agentType}</p>
            </div>
            <div className="propertie">
              <p>Properties in 2022: </p>
              <p>{person.countProjects}</p>
            </div>
            <div className="propertie">
              <p>Location :</p>
              <p>
                {person.district} - {person.locations}
              </p>
            </div>

            <div className="propertie">
              <p>Languages :</p>
              <p>{person.languages}</p>
            </div>
            <div className="propertie">
              <p>Last Activity : </p>
              <p>{person.lastActivity}</p>
            </div>
          </div>
        </div>
      </span>
    </Modal>
  );
};
export default PersonModal;
