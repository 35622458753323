import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";

class PayPalBtn extends React.Component {
    render() {
        const { amount, onSuccess, currency } = this.props;
        let isVisible = this.props.visible ? "block" : "none";    
        let status = this.props.status ? this.props.status : "NOT COMPLETED";
        let message = this.props.message ? this.props.message : "Please complete your payment";
        return (
        <div className="paypal-container-parent">
            <div className={"paypal-payment-form " + !isVisible}>
            <PayPalButton
                amount={amount}
                currency={currency}
                onSuccess={(details, data) => onSuccess(details, data)}
                options={{
                    currency: "CAD",
                    clientId:  "AUHgOCbU_yvn_1d0Bu5vzuUQBUoTmenqICpWHi0XDb8UGACXBYq5bx7JXmtp-DWaALFREodpru2hQruL" // "AdeTb7NrYiwxVdE4V2t2SQ9KjQbzgw-BqQHHUaiLyh1QIpO6Fa-K82aAowKULaA9JguOwBX3wUY7vPeI"// (sandbox)
                }}
            />
            </div>
            <div className={"paypal-status-message " + isVisible}>
                <div className="flex">                
                    <h3>Your Payment was {status}</h3>
                    <p>{message}</p>
                </div>
           </div> 
        </div>
        );
    }
}
export default PayPalBtn;