import React from "react";
import { Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const LinkItem = ({
  image,
  title,
  value,
  link: url,
  option,
  action,
  onClick: opeForm,
  optionServices,
  opeFormOptions,
  visible,
  className,
  userLocation,
  unavailable,
  unavailableText,
}) => {

  const theme = useTheme();
  const isSmallerThanPK = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {

    if (url) return window.open(url);
    else {
      if (action) opeFormOptions(optionServices, action);
      else opeForm({ title, value });
    }

  };

  return (
    <Tooltip
      title={unavailable && unavailableText ? unavailableText : ""}
      disableFocusListener
    >
      <li className={"link_item " + (visible || '')}>
        <button
          className="link_item_button"
          onClick={handleClick}
          disabled={unavailable}
        >
          <div className="image_wrap">
            <img src={image} alt={title} style={action === 'before' ? { transform: 'rotate(180deg)' } : {}}  className={className} />
          </div>
        </button>
        <p className="image_title">{title.split(' ')[0]}<br/>{
          title.split(' ')[1] 
            ? title.split(' ')[1].replace('_', ' ').replace('*', ' ')
            : ''
        }</p>
        {unavailable && unavailableText && isSmallerThanPK && (
          <div className="link_item_description">{unavailableText}</div>
        )}
      </li>
    </Tooltip>
  );
};

export default LinkItem;
