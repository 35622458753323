import React, { useRef, useState, useEffect } from 'react';
// import videoSrc from './../../Assets/videos/100CA.mp4';
// import videoSrcUS from './../../Assets/videos/100US.mp4';
import VideoModal from '../../Common/videoModal';

export const VideoPreview = ({ countryCode = "CA", className = '', videoId, index, previewImage, previewVideo, currentSlide }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [currentSlideCurrent, setCurrentSlide] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const videoPrev = useRef(null);

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const handlePlayVideo = () => {
        if (!videoPrev.current || isPlaying) return;
        setIsPlaying(true);
        videoPrev.current.play();
        videoPrev.current.currentTime = 0;
    };
    const handleStopVideo = () => {
        if (!videoPrev.current) return;
        setIsPlaying(false);
        videoPrev.current.pause();
        videoPrev.current.currentTime = 0;
    };
    // useEffect( () => {
    //     if (videoPrev.current) videoPrev.current.pause();

    //     if (currentSlideCurrent !== currentSlide) {
    //         setCurrentSlide(currentSlide);
    //     }

    //     if (videoRef.current) {
    //         videoRef.current.play();
    //     }
    // }, [currentSlide]);

   useEffect( () => {
        // if (videoPrev.current) videoPrev.current.pause();

        if (currentSlideCurrent !== currentSlide) {
            // setCurrentSlide(currentSlide);
            if (videoPrev.current) videoPrev.current.pause();
        }
        // if (videoRef.current) {
        //     videoRef.current.play();
        // }
    }, [currentSlide]);

    const previewUrl = previewVideo;

    return (
        <div className={`video-preview-wrapper ${className ? className : ''}`}>
            <div
                className={`video-preview-box testim ${
                    isPlaying ? 'video-preview-box--playing' : ''
                }`}
                onMouseOver={handlePlayVideo}
                onMouseLeave={handleStopVideo}
                onClick={handleOpen}
            >
                <div className='video-preview-controls'>
                    <svg
                        className='video-preview-controls_play'
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        version='1.1'
                        id='Layer_1'
                        x='0px'
                        y='0px'
                        viewBox='0 0 496.158 496.158'
                        style={{ enableBackground: 'new 0 0 496.158 496.158' }}
                        xmlSpace='preserve'
                    >
                        <path
                            fill='#42c4e4'
                            d='M496.158,248.085c0-137.021-111.07-248.082-248.076-248.082C111.07,0.002,0,111.062,0,248.085  c0,137.002,111.07,248.071,248.083,248.071C385.088,496.155,496.158,385.086,496.158,248.085z'
                        />
                        <path
                            fill='#FFFFFF'
                            d='M370.805,235.242L195.856,127.818c-4.776-2.934-11.061-3.061-15.951-0.322  c-4.979,2.785-8.071,8.059-8.071,13.762v214c0,5.693,3.083,10.963,8.046,13.752c2.353,1.32,5.024,2.02,7.725,2.02  c2.897,0,5.734-0.797,8.205-2.303l174.947-106.576c4.657-2.836,7.556-7.986,7.565-13.44  C378.332,243.258,375.452,238.096,370.805,235.242z'
                        />
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                    </svg>
                </div>

                {
                    previewUrl
                    ? <>
                        {iOS() 
                        ? (
                            <video autoPlay width='320' height='180' loop muted playsinline className='video-preview-box_video' 
                                poster={ (countryCode === 'CA'? "excelAssets/kitchens/caprv.jpg" : "excelAssets/kitchens/caprv.jpg")} src={previewUrl}>
                                <source src={previewUrl} type="video/mp4"></source>
                            </video>
                        ) : (
                            <video
                                loop
                                autoPlay={index === currentSlide ? true : false}
                                className='video-preview-box_video'
                                src={previewUrl}
                                width='320'
                                height='180'
                                type='video/mp4'
                                ref={index === currentSlide ? videoPrev : videoPrev}
                                muted
                            />
                        )}
                    </> 
                    : <>
                        <img src={previewImage} alt="previewImage" />
                    </>
                }



            </div>
            <VideoModal
                isOpen={isOpen}
                videoId={videoId}
                handleClose={handleClose}
            />
        </div>
    );
};

export default VideoPreview;

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
}
