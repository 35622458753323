import React from 'react'
import ReactDOM from 'react-dom';
import ReactPDF, { PDFViewer, BlobProvider, usePDF} from '@react-pdf/renderer';
import PdfDocument from "./bathroomForm"; 
import Image from "../Assets/images/example.jpeg";
//import ReactPDF from '@react-pdf/renderer';

// Create Document Component
// export default function example(props) {
//     ReactPDF.render(<PdfDocument data={dataForm} />, `${__dirname}/example.pdf`);
// }

// const App = (data) => (
//   <PDFViewer style={{width:'100%',height:'1000px'}}>
//       <PdfDocument data={data.form} images={data.images} />
//   </PDFViewer>
// );

// const App = (data) => (
//   <div>
//     <BlobProvider document={MyDoc}>
//       {({ blob, url, loading, error }) => {
//         // Do whatever you need with blob here
//         return <div>There's something going on on the fly</div>;
//       }}
//     </BlobProvider>
//   </div>
// );

//const MyDoc = <pdfDocument form={formData} images={images} uid={uid} />

// const App = () => {
//   const [instance, updateInstance] = usePDF({ document: MyDoc });

//   if (instance.loading) return <div>Loading ...</div>;

//   if (instance.error) return <div>Something went wrong: {error}</div>;

//   return (
//     <a href={instance.url} download="test.pdf">
//       Download
//     </a>
//   );
// }

export default function setup(props) {
  
  const form = {
    address: "90283 Address Brampton On",
    ageHome: "26 years",
    sizeOfHome: "9834 Sq Feet",
    powderRoom: "No",
    bathroom: "No",
    masterBathroom: "Yes",
    link:"https://bathroomlayoutfiles.s3.amazonaws.com/bathroom_layout-be526ca9-fc71-4594-b9f5-25a8f75bb13b.zip"
  }

  // const form = {
  //   address: props.form.address,
  //   ageHome: props.form.ageHome,
  //   sizeOfHome: props.form.sizeOfHome,
  //   powderRoom: props.form.powderRoom,
  //   bathroom: props.form.bathroom,
  //   masterBathroom: props.form.masterBathroom,
  //   link:"https://bathroomlayoutfiles.s3.amazonaws.com/bathroom_layout-"+props.uid+".zip"
  // }

  const images = {
    layout: Image,
    backwall: Image,
    frontwall: Image,
    leftwall: Image,
    rightwall: Image,
  };

  // const images = {
  //   layout: props.images.layout,
  //   backwall: props.images.backwall,
  //   frontwall: props.images.frontwall,
  //   leftwall: props.images.leftwall,
  //   rightwall: props.images.rightwall,
  // };

  //const MyDoc = <pdfDocument form={form} images={images} />;
  
  //ReactPDF.render(<pdfDocument form={form} images={images} />, `${__dirname}/example.pdf`);
  //return ReactDOM.render(<PdfDocument data={form} images={images} />, document.getElementById('root'));
  //return ReactDOM.render(<App form={form} images={images} />, document.getElementById('root'));

  return null;
}

