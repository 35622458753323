import axios from "axios";
import { GoogleSpreadsheet, SpreadsheetApp } from "google-spreadsheet";

const loadSpreadsheetPage = async (docId = "16ftfcJbrm9wxMpXVa6RR1-1CyDa2zEfnqRxvsSMscPQ") => {
  // Initialize the sheet - doc ID is the long id in the sheets URL
  let locations = [],
    languages = [],
    agentType = [],
    lastActivity = [],
    items = [];

  const doc = new GoogleSpreadsheet(docId);

  try {
    // Initialize Auth - see https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
    await doc.useServiceAccountAuth({
      // env var values are copied from service account credentials generated by google
      // see "Authentication" section in docs for more info
      client_email: "agm-googlesheets@agmrenovations-371710.iam.gserviceaccount.com",
      private_key:
        "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCmPCr9i3c5kQ5Z\nMNUqGezcnBV0TUuVmirss0Qq8S9AY0uYGvdn3YmGhuDDt1XnmNoa42BTdJLQOnRU\ndzPlZmB3cKZ/+wCV8Gn6/UmKpi/lCYTjcxiNS1KHZFH8Xe+r/j7Ai1FYN+Kng8+y\n4m4QTW/RDkOBqAn/OimMeEMfxIqsZKyFybIvHVl7upoHv1bb8g/P/MgpLPW8S/eb\nYuaH6kSv4QViWeEPWU6SgxfvRInVN6H3M+Sh4gkcsazGOEq2VLTkzvRl3ETPTbsw\nkCPhvwlBFBDv3pMealZBgSfQxXp+et3wF1E6dws1PykI8waE1s0gxwQq+wQAmSkX\nFmB0LIbvAgMBAAECggEANE4oRMD2UbG3rw6zc9zWb4/ZHFAZ4UeyC+pYH57l/D+C\nEKkjgZXuaNrVrB9qs0WgEufKblf2vfg2lEExnhO5dLuIWCqVQ5hy8Ft0IWErHskA\n/ERBzGuP2zQQq/SurhKAkjsD7FshQQ4zcxjWAuEqtr/jM8+43YrieeV5UWyZKaa9\nmgqIwotG/eEhXI1tj+qKn4WuYr7GtZJu8jtnnHGsOjKiIOWdaBrL+cCsbvESpSVk\nphPw43nuieZiiESpyWUR3hY7a5onXaYI/eV03Eq7Nnbp9DNYjhSx3n8Kk4pyajJu\nqpZYPkGbBx6Zz4uOu/7eSXTp38tSvw32CChzan72AQKBgQDcir/K+7CuCSpOqB0M\n1LRok8iNHm8JTEIDA0JzshGxqEdWzOZKfGoUazuPo23GHKM1CESYy1/PyWPwz3+3\ne5lIS+uLgZhoUnS1eMLz5JW+UfPwpocYzB2cgRFA2FfS1CNzYb8pUXeh8jzgFLNo\nCueXSSNvenXkQLKhQLaFAqjRAQKBgQDA9jUuwdsdeReY7lqeYXflQeLXlXjkpkBZ\nd2j9Fd073m97HnYhH6NixFcJLR59B6waQlc0zcVmFPV2KUIT58FUjF+MeUgukzOK\nh4ppqFNScUPzAmBk/O8ux/YgkQ1hfZBs6h/1GjkinTSAqy83StOCj2JJ6HUaQfie\nFS+bcnpn7wKBgDA3RWcjT6U867r2liNP/YE/jgEMPkpSGbx+4fBZZfTpTW90rIRs\nXuTH7OWiXUWKzUOy4wxkqjXLWcY0F0AODZz4vC5rzt4/6LQkCrs3pmVNlX9vaOnK\n9k5ZCWSb59d3+agoHprx7K3DP/vdirucZrV0kAK3Sz7zxhnrA/xgcHgBAoGBAI5j\nH8to8S9Gc8s911CXy8k4XROIELVomINsQeS/hYjwEoNfbvvyRLZU+4Sh7qWES9L0\nsTUdwlQZkyemOkRF97tT+g3GUSVNKfNHITPmKdXkDqf7UPY0TqrOkyhk4OLXrQMx\noHNU7mREbMGlkCnU5U6qT3UKO58snzDHoWGrj/exAoGBAINzsyac2jMRiahQlXoQ\n0uS8lsXyzT7z2kmsE/rObigRRkV6kfd0aptraRrhS8R28ad+25xKxlglszLu0Kx4\ni6pjZGzEmEHvhYuWz9nCVf+5bXUOLVyOJDLoJ5/o5WhL88G1D7lfM8c1TRGDpoar\n/wp86gZNzWZJSgP50f1tJ3rV\n-----END PRIVATE KEY-----\n",
    });
    await doc.loadInfo(); // loads document properties and worksheets
    const worksheets = doc.sheetsByIndex[0],
      worksheetFilter = doc.sheetsByIndex[1];

    const rows = await worksheets.getRows(),
      filterdRows = await worksheetFilter.getRows();

    const rowValues = rows.filter((row) => row?._rawData?.length > 0);
    const rowFiltered = filterdRows.filter((row) => row?._rawData?.length > 0);

    rowValues.forEach((e) => {
      const { _rawData, _sheet } = e;
      const itemReal = {
        name: _rawData[0],
        phone: _rawData[1],
        countProjects: _rawData[2],
        locations: _rawData[3],
        languages: _rawData[4],
        agentType: _rawData[5],
        lastActivity: _rawData[6],
        photoId: _rawData[7],
        brokerageName: _rawData[8],
        email: _rawData[9],
        district: _rawData[10],
      };
      items.push(itemReal);
    });

    rowFiltered.forEach((e) => {
      const { _rawData, _sheet } = e;

      if (_rawData[0]) {
        locations.push(_rawData[0]);
      }
      if (_rawData[1]) {
        languages.push(_rawData[1]);
      }
      if (_rawData[2]) {
        agentType.push(_rawData[2]);
      }
      if (_rawData[3]) {
        lastActivity.push(_rawData[3]);
      }
    });
  } catch (error) {
    console.error(`Error loading spreadsheets from google.docs: ${error}`);
  }
  return { items, locations, languages, agentType, lastActivity };
};

export { loadSpreadsheetPage };
