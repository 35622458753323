import * as React from 'react';
import { useHistory } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@material-ui/core/styles';

import HighlightOffIcon from '@mui/icons-material/Close';
import baneerOfferRef from '../Assets/images/Referral-Popup-BLUE.png';

const useStyles = makeStyles({
    rootD: {
      ['& .MuiPaper-root']: {
        boxShadow: 'none',
        background: 'transparent',
        margin: '0',
        background: '#3330',
        boxShadow: 'none',
      }
    },
    customIconCloseChat: {
      color: '#eee',
      opacity: '0.75',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '28px',
        top: "25px",
        width: '38px',
        height: '38px',
    },
});

export default function MaxWidthDialog({
    open,
    handleClose
}) {

  const classes = useStyles();
  const history = useHistory();
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const referralHandler = () => {
        handleClose();
        history.push("/referrals");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.rootD}
      >
        <DialogContent>
            <img style={{ cursor: 'pointer', filter: 'drop-shadow(2px 4px 6px black)' }} onClick={referralHandler} src={baneerOfferRef} />
        </DialogContent>
        <HighlightOffIcon style={{ zIndex: '100' }} className={classes.customIconCloseChat} onClick={handleClose}/>
      </Dialog>
    </React.Fragment>
  );

}