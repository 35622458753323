import React from 'react'

export const Experience = (props) => {
  return (
    <section className="about-section-1">
      <div className="container">
        <h3>
          {/* <span>Experience</span> leads to expertise! */}
          <span>Backed by Decades of Experience</span> Since 1998
        </h3>

        <p>{props.paragraph}</p>

      </div>
    </section>
  )
}