import React, {useState} from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { VideoPreview } from './VideoPreview';
import { sliderSettings } from '../Home/constants';

export const VideoTestimonials = ({ items }) => {

  const [currentSlide, setSlideIndex] = useState(0);

  return (
    <section className="latest-works">
      <h2 style={{  paddingBottom: '40px' }}>What our clients say about us</h2>
      <div style={{ width: '90%', marginLeft: '5%' }} className="works-slider">
        <Slider  {...{ ...sliderSettings, speed: 1000, autoplaySpeed: 3000, autoplay: false,
              beforeChange: function(currentSlide, nextSlide) {
                setSlideIndex(nextSlide);
              },
         }}>
          {items.map((item, index) => (
            <div key={index} className="latest-work-slider-item video">
               <VideoPreview
                videoId={item.videoId} 
                currentSlide={currentSlide} 
                previewImage={item.previewImage} 
                previewVideo={item.previewVideo} 
                index={index} 
               />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}