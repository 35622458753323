import React, { Component } from "react";
import { Link } from "react-router-dom";

import BlogImg1 from "../../Assets/images/news/agm-designline-article.jpg";
import BlogImg2 from "../../Assets/images/news/agm-city-ottawa-interview.png";
import BlogImg3 from "../../Assets/images/news/680News16x9.jpg";

export class OurNewsBlog extends Component {
    static propTypes = {};

    render() {

        const blogs = [
            {
                index_key: 3,
                image: BlogImg3,
                date: "Friday June 04, 2021",
                title: "AGM Bathroom Renovations",
                blog_body: "If you bathroom is over 10 years old, it's time to renovate!",
                link: "https://bathroomrenovations.com/audio/AGM-Bathroom-680-News.mp3"
            },
            {
                index_key: 1,
                image: BlogImg2,
                date: "Wednesday May 26, 2021",
                title: "Ivan Atanasov(CEO AGM) live interview on CTV",
                blog_body: "Proud of our growth in the industry, culminating in professional level renovations featured across Canada. Check out our CTV Live Interview.",
                link: "https://ottawa.ctvnews.ca/video?clipId=2208617"
            },
            {
                index_key: 2,
                image: BlogImg1,
                date: "Monday May 17, 2021",
                title: "AGM Featured in Design Line Magazine",
                blog_body: "How the leading renovation company reimagines renovations – and launches careers By Designlines in partnership with AGM Renovations",
                link: "https://www.designlinesmagazine.com/agm-basements-transforms-gta-homes/"
            }
        ];
      
        return (
            <>
            <section className="video-intro about-us">
              <div className="container">
                <div className="cnt">
                  <h2>Our News Blog</h2>
                  <h4>Recent Events</h4>
                </div>
              </div>
            </section>
            <section className="home-start blog">
                <div className="container">
                    
                {blogs.map((blog,idx) => (
                    <div key={idx} className={'blog-item'}>
                        <div className="cnt-blog-right">
                            <img src={blog.image} alt="" className={'blog-image'} />
                        </div>
                        <div className="cnt-blog-left">
                            <h2>{blog.title}</h2>
                                <span>{blog.date}</span><br/>
                            <p>{blog.blog_body}</p>
                            <a href={blog.link} className="blue-btn" target="_blank">
                            Click to view
                            </a>
                        </div>
                    </div>
                ))}
                
            </div>
        </section>
        </>
        );
    }
}

export default OurNewsBlog;