import React, { Component } from "react";
import PropTypes from "prop-types";

/* eslint-disable */
class GeneralBanner extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string
  };

  state = {};

  render() {
    let style = "sticky-nav-top-margin";
    let id = "inner-banner";
    if(this.props.page=="careers" && this.props.img){
      style = "sticky-nav-top-margin careers"
    } else if(this.props.page=="our-news-blog" && this.props.img){
      style = "sticky-nav-top-margin blog"
    }
    const { title, subTitle } = this.props;
    return (
      <div id={id} className={style}>
        <div className="inner-banner-content">
          <div className="container">
            <h1 style={{textShadow:"none", fontWeight:"500"}}>{title}</h1>
            {subTitle ? <p>{subTitle}</p> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralBanner;
