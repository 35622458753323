import React, { createRef, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100%",
    backgroundColor: "#00000061",
    zIndex: 101,
    position: "absolute",
    // userSelect: 'none',
    pointerEvents: "none",
    opacity: 1,
    // filter: 'blur(3px)',
    transition: "opacity 1s ease",

    "&.complete": {
      opacity: 0,
    },
  },
  root: {
    width: "100%",
    marginLeft: "calc(50% - 20px)",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    position: "absolute",
    top: "44%",
    left: "calc(20% - 20px)",
    color: "#fff",
    paddingBottom: "25px",
    padding: "20px",
    width: "60%",
    paddingBottom: "25px",
    borderRadius: "4px",
  },
  message: {
    textAlign: "center",
    marginBottom: "15px",
    fontFamily: "Josefin Sans",
    textTransform: "uppercase",
  },
}));

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#fff",
  },
  top: {
    color: "#45bfed",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default function Loading({ isReady }) {
  const classes = useStyles();
  const container = createRef();

  useEffect(() => {
    if (isReady) {
      container.current.classList.add("complete");
      setTimeout(() => {
        if (container.current) container.current.style.display = "none";
      }, 1000);
    }
  });

  return (
    <div ref={container} className={classes.main}>
      <div className={classes.container}>
        <div className={classes.message}>
          {"Please wait, we are loading your Real Estate Team"}
        </div>
        <div>
          <div className={classes.root}>
            <FacebookCircularProgress />
          </div>
        </div>
      </div>
    </div>
  );
}
