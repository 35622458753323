import React from 'react';

export const Enjoy = ({ title, imgProps, className }) => {
  return (
    <>
      <section className={`enjoy ${className || ''}`}>
        <h2>{title}</h2>
      </section>
      <div className="enjoyImg">
        <img alt="" {...imgProps} />
      </div>
    </>
  )
}