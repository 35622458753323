import React from 'react'
import s from './styles.module.scss';

const getGeoOptions = () => {
	const location = sessionStorage.getItem("location");
	const postal   = sessionStorage.getItem("postal");
	const city     = sessionStorage.getItem("city");
	const province = sessionStorage.getItem("province");
	const region   = sessionStorage.getItem("region");

	let locationQueryParam = `${city || location || ""}_${province || ""}`;


	return {
		postal,
		loc: locationQueryParam,
		reg: region,
	}
}

const computeUrlBasedOnQuery = (link, query) => {
	if (!query) return link;

	const isGeoSaved = !Object.entries(query).some(([_, val]) => !val );

	if (!isGeoSaved) {
		if (query.option) return `${link}?option=${query.option}`;
		else return link;
	}
 
	const queryStr = Object.entries(query).filter(([_, val]) => !!val).map(([key, value]) => `${key}=${value}`).join("&");
	if (!queryStr) return link;

	return `${link}?${queryStr}`;
}

const Service = ({ service, className = "", ...rest }) => {

	const { image, title, query, link } = service;

	const queryAndGeoParams = {
		...(query || {}),
		...getGeoOptions(),
	};
	
const Line = ({ color }) => (
    <hr
		className={s.break}
    />
);
	return (
		<>
		<a target="_blank" rel="noopener noreferrer" href={computeUrlBasedOnQuery(link, queryAndGeoParams)} className={`${s.service} ${className}`}>
			{image && <img src={service.image} className={s.service_img} alt="" />}
			<p className={s.service_title}>
				{title || ""}
			</p>
		</a>
		<Line/>
		</>
	)
}

export default Service