import React, { Component, Suspense, lazy } from 'react';
import Dropzone from 'react-dropzone';
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class UploadFiles extends Component {

  static propTypes = {
  };

  state = {
    files: [],
    isAgreed: false,
  };

  onDrop = (files) => {
    files.map(file => {
      const joinedFiles = this.state.files.concat(file);
      this.setState({
        files: joinedFiles
      });
    });
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  stepComplete = (e) => {
    e.preventDefault();
    this.props.stepComplete(this.state.files);
  }

  render() {

    const files = this.state.files.map( (file, index) => (
      <li key={index}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section id="file-upload" className={this.props.hasWarrantyFileError ? 'error' : ''}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="text-and-button-holder">
                  <div className={`instructions ${this.props.hasWarrantyFileError ? 'error' : ''}`}><h3>Attach your final invoice or certificate of final completion*</h3></div>
                  <div className="button new-upload-button"><i className="fas fa-cloud-upload-alt" /> Attach File</div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>

        <ul>{files}</ul>

        <br/>
        <p>
          In order to request corrections to defects in the Work during the Warranty Period, the Client must submit the warranty within the Warranty Period and a $350.00 security deposit. The Client and Contractor shall schedule an inspection (the “Warranty Inspection”) within 3-4 weeks of receipt of the online warranty form at which time the Contractor shall determine, at its discretion, whether the repairs are covered under warranty. If the repairs are warrantable, all warranty repairs will be completed within 2 (two) weeks of the Warranty Inspection. If the repair is deemed warrantable, the Client’s $350.00 deposit will be refunded.
        </p>
        <FormControlLabel
          control={<Checkbox checked={this.state.isAgreed} onChange={()=>{ this.setState({ isAgreed: !this.state.isAgreed })}} name="checkedA" />}
          label="I agree with terms and conditions described above"
        />
        <br/>

        { this.state.isAgreed && (
          <>
            {(this.props.isFromWarranty && this.props.buttonTitle)
              ? <div className={this.props.isFromWarranty && this.props.buttonTitle ? 'continue-btn' : 'form-button'} onClick={this.stepComplete}>
                  <a><span className="btn-text">{this.props.buttonTitle ? this.props.buttonTitle : 'Next Section'}</span></a>
                </div> 
              : <div className={this.props.isFromWarranty && this.props.buttonTitle ? 'continue-btn' : 'form-button'}>
                  <Button variant="contained" color="primary">
                    <span className="btn-text">{this.props.buttonTitle ? this.props.buttonTitle : 'Next Section'}</span>
                  </Button>
                </div>
            }
          </>
        )}


      </>
    );

  }
}

export default UploadFiles;