import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function InputChecked({ item, handler, sorValue }) {
  const [checked, setChecked] = useState(false);
  const mobile = window.innerWidth < 650 ? true : false;

  return (
    <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label": {
          color: "#1e3c56",
          fontSize: mobile ? "small" : "large",
          fontWeight: 400,
        },
      }}
      control={
        <Checkbox
          onClick={() => handler(sorValue, item)}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 18,
            },
          }}
        />
      }
      label={item}
    />
  );
}
