import React, { Component, Suspense, lazy, ref } from "react";
import Dropzone from 'react-dropzone';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@mui/material/Container';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import agent from '../../Assets/images/agent.jpeg';

class Issues extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.uploadRef = React.createRef();
  }

  state = {
    isProgress: false,
    type_of_issue: '',
    issue_files: [],
    description: '',
    pre30: this.props.pre30,
    fileName: null,
    errors: {}
  };

  onDrop = (files) => {
    files.map(file => {
      const joinedFiles = this.state.issue_files.concat(file);
      this.setState({
        issue_files: joinedFiles,
        errors: {
          ...this.state.errors,
          fileError: false,
        },
        fileName: joinedFiles.join(',')
      });
    });
  };

  onFileChange = (e) => {
    var files = e.target.files;
    var name = e.target.files[0].name;
    console.log(files);
    var filesArr = Array.prototype.slice.call(files);
    console.log(filesArr);
    //setFiles([...files, e.target.files[0]]);
    this.setState({
      issue_files: [...this.state.issue_files, ...filesArr],
      errors: {
        ...this.state.errors,
        fileError: false
      }
    });
    console.log(filesArr.join(','));
    this.setState({ fileName: filesArr.join(',') });
  }

  showFileUpload() {
    this.fileUpload.current.click();
  }

  removeFile = (f) => {
    this.setState({ issue_files: this.state.issue_files.filter(x => x !== f) });
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: false
      }
    })
  }

  stepComplete = (e) => {
    const { type_of_issue, issue_files, description } = this.state;
    console.log(type_of_issue, issue_files, description)
    e.preventDefault();
    this.setState({
      errors: {
        type_of_issue: !Boolean(type_of_issue),
        description: !Boolean(description),
        fileError: !Boolean(issue_files.length)
      }
    });
    if (!Boolean(this.state.type_of_issue) || !Boolean(this.state.description) || !(this.state.issue_files && this.state.issue_files.length)) {
      return
    }
    this.setState({ isProgress: true })
    if (type_of_issue && issue_files && description) {
      const stepThreeInfo = {
        type_of_issue,
        issue_files,
        description
      };
      this.props.stepComplete(stepThreeInfo);
    } else {
      setTimeout(() => { this.setState({ isProgress: false }); }, 1500)
    }
  }
  onClickUpload = () => {
    console.log(this.uploadRef)
    this.uploadRef.current.click()
  }
  render() {

    const MyButton = styled(Button)({
      background: 'linear-gradient(45deg, #23dcde 30%, #45bfee 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(69, 188, 237, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      float: 'right'
    });

    const files = this.state.issue_files.map( (file, index) => (
      <li key={index}>
        {file.name} - {file.size} bytes
      </li>
    ));
    return (
      <Container maxWidth="md">
        <div className="issues new">
          <Grid container spacing={2} className="issue-contain">
            <Grid item xs={12} sm={12} className="banner">
              <h1>Tell us about your issues</h1>
              <div className="agent-image-holder">
                <img src={agent} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className="issue-type">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="type_of_issue" style={{ backgroundColor: 'white', fontSize: 21, fontFamily: 'Quicksand', color: this.state.errors.type_of_issue ? 'red' : 'inherit' }} >Type of Issue *</InputLabel>
                <Select
                  fullWidth
                  labelId="type_of_issue"
                  label="Type of Issue?"
                  name="type_of_issue"
                  required
                  select
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} // font size of input label
                  onChange={(e) => this.inputChange(e)}
                  SelectProps={{ native: false }}
                  id="ProjectTime"
                  error={this.state.errors.type_of_issue}
                  variant="outlined"
                >
                  <MenuItem value=""><em>-- Please Select --</em></MenuItem>
                  <MenuItem value="Cabinets kitchen/bar/wine bar">
                    Cabinets kitchen/bar/wine bar
                  </MenuItem>
                  <MenuItem value="Ceiling &amp; Wall System">
                    Ceiling &amp; Wall System
                  </MenuItem>
                  <MenuItem value="Doors">Doors</MenuItem>
                  <MenuItem value="Electrical">Electrical</MenuItem>
                  <MenuItem value="Fire Alarm/Sprinklers">
                    Fire Alarm/Sprinklers
                  </MenuItem>
                  <MenuItem value="Fireplace/HVAC">Fireplace/HVAC</MenuItem>
                  <MenuItem value="Flooring">Flooring</MenuItem>
                  <MenuItem value="Foundation Cracks">Foundation Cracks</MenuItem>
                  <MenuItem value="Framing/Drywall/Taping">
                    Framing/Drywall/Taping
                  </MenuItem>
                  <MenuItem value="Glass door/wall/shower">
                    Glass door/wall/shower
                  </MenuItem>
                  <MenuItem value="Granite/Laminate Counter tops">
                    Granite/Laminate Counter tops
                  </MenuItem>
                  <MenuItem value="Painting">Painting</MenuItem>
                  <MenuItem value="Plumbing">Plumbing</MenuItem>
                  <MenuItem value="Separate Entrances">Separate Entrances</MenuItem>
                  <MenuItem value="Tiles/ Stone">Tiles/ Stone</MenuItem>
                  <MenuItem value="Windows">Windows</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} className="issue-description">
              <TextField
                autoComplete="description"
                name="description"
                variant="outlined"
                required
                fullWidth
                multiline
                rows={4}
                id="description"
                label="Please type your issue here"
                inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} // font size of input label
                onChange={(e) => this.inputChange(e)}
                error={this.state.errors.description}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="issue-description">
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className={this.state.errors.fileError ? 'error upload' : 'upload'}>
                    <div {...getRootProps()} className="w-100">
                      <input {...getInputProps()} />
                      <div className="text-and-button-holder">
                        <div className={`${this.state.errors.fileError ? 'error' : ''}`}><h3>Attach Photos *</h3></div>
                        <div className="button new-upload-button"><i className="fas fa-cloud-upload-alt" /> Attach Files</div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>

            <ul className="files">
              {this.state.issue_files.map( (x,i) =>
                <li key={i} className="file-preview" onClick={this.removeFile.bind(this, x)}>{x.name}</li>
              )}
            </ul>

            <MyButton
              style={this.state.isProgress ? {
                background: '#c5c5c5',
                pointerEvents: 'none',
                boxShadow: '0 3px 5px 2px rgb(175 175 175 / 30%)',
              } : { background: 'linear-gradient(45deg, #23dcde 30%, #45bfee 90%)' }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              // className={this.props.classes.submitLog}
              onClick={(e) => this.stepComplete(e)}
            >
              {this.props.buttonTitle ? this.props.buttonTitle : 'Continue'}
            </MyButton>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default Issues;
