import React from "react";

import person_1 from "../../Assets/images/aboutagm.jpg";

export const HowWeStarted = ({ paragraphs }) => {
  return (
    <div style={{ backgroundColor: 'rgb(239 241 240)' }}>
    <section className="about-section-2 howstart">
      <div className="container">
        <h2>How We Started</h2>
        {Array.isArray(paragraphs) ? (
          paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)
        ) : (
          <p>
            In 1998 Ivan immigrated to Canada with $200 in his pocket and took his first job in Renovations.  He made the owner an offer he couldn’t refuse – he offered to work for free in order to learn the industry.  Ivan was appalled by what he saw. There were huge delays, shortcuts taken, projects abandoned midway, hidden fees and the clients almost always kept in the dark. A few months later he left that contractor with the desire to revolutionize the industry and find a way to help homeowners get the homes they dreamed of and the service they deserved. 
            <br/> <br/>
            20 years later he has built the most successful Renovations company in the country.  His entire business is built on Integrity, Excellence and commitment to Quality Assurance.
          </p>
        )}
      </div>

      <div className="founders">
          <div className="box">
     
              <div className="imgContainer">
                <img src={person_1} style={{ zIndex: '10', position: 'relative' }} alt="" />
              </div>
      
          </div>
       </div>
    </section>
    </div>
  );
};
