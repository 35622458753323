import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import station1us from "../Assets/images/Stations/USA/ExAu9Qrjka.png";
import station2us from "../Assets/images/Stations/USA/KCBSAM_800x800.png";
import station3us from "../Assets/images/Stations/USA/KDVR-31-FOX-Denver.png";
import station4us from "../Assets/images/Stations/USA/koakhowXL.png";
import station5us from "../Assets/images/Stations/USA/kusa-logo-of-nbc-television-png-favpng.png";
import station6us from "../Assets/images/Stations/USA/logo200.jpg";
import station7us from "../Assets/images/Stations/USA/NBC-4-News-Washington-2.jpg";
import station8us from "../Assets/images/Stations/USA/Watch-KNTV-Online.jpg";
import station9us from "../Assets/images/Stations/USA/wdrv_logo.png";
import station10us from "../Assets/images/Stations/USA/WLS-TV_logo.svg.png";
import station11us from "../Assets/images/Stations/USA/WtopNewsLogo_vertical_500x500.png";
import station12us from "../Assets/images/Stations/USA/13abc.png";

const USStations = [
  { img: station1us },
  { style: {height: '100px'}, img: station2us },
  { img: station3us },
  { img: station4us },
  { img: station5us },
  { img: station6us },
  { img: station7us },
  { img: station8us },
  { img: station9us },
  { style: {height: '110px'}, img: station10us },
  { img: station11us },
  { img: station12us },
];

export default function SwipeToSlide() {

    const settings = {
      className: "",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 8,
      slidesToScroll: 4,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1525,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
      ],
      afterChange: function (index) {},
    };
    return (
      <div className="stationSlider">
        <h4 style={{ textAlign: "center", margin: "0", color: "grey" }}>
          {" "}
          AS FEATURED ON
        </h4>
        <Slider {...settings} style={{marginTop: '-20px'}}>
          {USStations.map( (station, index) => (
            <div key={index} className='stationImg'>
              <img style={{ opacity: 0.65, height: station.style?.height }} src={station.img} />
            </div>
          ))}
        </Slider>
      </div>
    );

}
