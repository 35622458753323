import React from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";

export const SliderText = (props) => {

const mobileSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
        breakpoint: 769,
        settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        },
        },
    ],
    };
    
  return (
    <div className="mobile-visible slider-box">      
        <h4>NO NEED TO:</h4>
        <div className="text-slider">
            <Slider {...mobileSettings}>
                <div className="box" data-number="01">
                <b>Deal with contractors</b>
                </div>
                <div className="box" data-number="02">
                <b>Invite strangers to your home</b>
                </div>
                <div className="box" data-number="03">
                <b>Drive to showrooms</b>
                </div>
                <div className="box" data-number="04">
                <b>No more wasted time</b>
                </div>
            </Slider>
        </div>
        <br/>
         { props.showButton  
            ? (<Link to="/get-started" className="blue-btn">
                Get Free Quote
               </Link>)
            : (<Link to="/get-started" className="blue-btn optional">
                Get Free Quote
               </Link>)
         }

    </div>
  )
}