import React from 'react';
import Career from '../../Containers/Career/Career';

export const CareersPage = (props) => {

  return (
    <>
      <Career />
    </>
  );
}