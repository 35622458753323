import React from 'react';

export const TopSection = () => {
  return (
    <section className="video-intro about-us">
      <div className="container">
        <div className="cnt">
          <h2>About Us</h2>
          <h4>The Team</h4>
          <div>
            <a
              href="/careers"
              className="blue-btn"
              rel="noopener noreferrer"
            >
              APPLY FOR JOB!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}