import React, { useMemo } from "react";
import { memo } from "react";

const AgentImg = ({ photoId }) => {
  const img = useMemo(() => {
    return `https://drive.google.com/uc?export=view&id=${photoId}`;
  }, [photoId]);
  return <img src={img} alt="drive image" />;
};
export default memo(AgentImg);
