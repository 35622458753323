import React, { Component, Suspense, lazy } from "react";
import PayPalBtn from "../../../Common/PayPalBtn";

class Payments extends Component {
  static propTypes = {};

  state = {
    loading: this.props.loading,
    visible: false,
    disabled: true
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  buttonClick = (e) => {
    e.preventDefault();
    this.stepComplete()
  }
  stepComplete = (e) => {
    //const {description} = this.state;
    this.setState({ 'loading': true });
    const stepFourInfo = {
      order_id: this.state.payData.orderID
    };
    // console.log("stepFourInfo")
    console.log(stepFourInfo)
    this.props.stepComplete(stepFourInfo);
  }

  paymentHandler = (details, data) => {

    /** Here you can call your backend API
      endpoint and update the database */
    // console.log(details);
    // console.log(data);
    //console.log(details.status);

    this.setState({
      payData: data,
      'status': details.status
    });

    if (details.status == "COMPLETED") {
      this.setState({
        'visible': true,
        'message': "Please Click Submit to Complete Form",
        'disabled': false
      }, () => {
        this.stepComplete();
      });
    } else {
      this.setState({
        'visible': true,
        'message': "There was an Error during your Payment, Please Review Your Payments Methods and Try Again",
        'disabled': true
      })
    }
  }

  render() {
    let disabled = this.state.disabled ? "disabled" : ""
    return (
      <>
        <Suspense fallback="" />
        <div className="no-icon form-fields issues">
          <div className="">
            <div className="type-of-issue">
              <div className="input-holder">
                <label htmlFor="type_of_issue">Complete Deposit Payment:*</label>
                <div className="error_message"></div>
              </div>
            </div>
            {/* <!-- end type of issue --> */}
          </div>
          {/* <!-- end left --> */}
        </div>
        <div className="deposit-total">
          <h2>Deposit Total: <span>$350</span></h2>
        </div>
        <div className="mb-0">
          <PayPalBtn
            visible={this.state.visible}
            status={this.state.status}
            message={this.state.message}
            amount={350}
            onSuccess={this.paymentHandler}
            currency={"CAD"} />
        </div>
        <div className="form-button mb-0">
          {
            this.props.loading ? (
              <button className="next-btn no-cursor">
                <span className="btn-text">Loading...</span>
              </button>
            ) :
              <button className="next-btn" onClick={this.buttonClick} disabled={disabled}>
                <span className="btn-text">Submit</span>
              </button>
          }
        </div>
      </>
    );
  }
}

export default Payments;