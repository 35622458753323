import React from 'react'

export const CreatorFeatures = ({ items }) => {
  return (
    <section className="creator-wp">
      <h2>
        <span>What can you do with our</span>
        Advanced 3D Creator?
      </h2>
      <ul>
        {items.map((item, index) => (
          <li className="row" key={index}>
            <div className="cnt">
              <div className="text">
                {item.text}
              </div>
            </div>
            <img className="image" alt="" {...item.imgProps} />
          </li>
        ))}
      </ul>
    </section>
  )
}