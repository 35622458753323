import React from 'react';
import Grid from "@material-ui/core/Grid";

import bestHouz from "../../Assets/images/bestHouz2021.png";
import BILDLogo from "../../Assets/images/bild-logo.png";
import RenomarkLogo from "../../Assets/images/renomark_logo.png";
import HomeStars from "../../Assets/images/best-of-homestars.png";

export const OurAwards = () => {
  return (
    <section style={{ textAlign: "center" }}>
      <div style={{ backgroundColor: "#fff", margin: "auto" }}>
        <div
          className={"home-recent-work"}
        >
          <div className={""}>
            <h1 className="">
              Highest Rated Renovators
            </h1>
          </div>
          <Grid
            container={true}
            direction={"row"}
            alignContent={"space-between"}
            alignItems={"center"}
            spacing={5}
            style={{ margin: "2em auto", width: "80%" }}
          >
            <Grid item={true} xs={3} sm={3} style={{ marginBottom: "1em" }}>
              <a href="https://homestars.com/companies/2807659-agm-basements" target="_blank" rel="noopener noreferrer">
                <img src={HomeStars} className="award-img" alt="Home Stars Logo" />
              </a>
            </Grid>
            <Grid item={true} xs={3} sm={3} style={{ marginBottom: "1em" }}>
              <img src={BILDLogo} className="award-img" alt="BILD logo" />
            </Grid>
            <Grid item={true} xs={3} sm={3} style={{ marginBottom: "1em" }}>
              <a href="https://www.houzz.com/professionals/design-build-firms/agm-renovations-pfvwus-pf~126163196" target="_blank" rel="noopener noreferrer">
                <img src={bestHouz} className="award-img" alt="best Houz logo" />
              </a>
            </Grid>
            <Grid item={true} xs={3} sm={3} style={{ marginBottom: "1em" }}>
              <img src={RenomarkLogo} className="award-img" alt="Renomark Logo" />
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  )
}