import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  loading: {
    '& .app-loading-element__container .app-loading-element__progress': {
      display: 'none', // hides loader inside loader so we don't show 2 loaders
    }
  },
  loaderContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullscreen: {
    position: 'fixed',
  }
})


const LoadingElement = ({ children, loading, className, fullscreen, ...rest }) => {
  const classes = useStyles();

  const loader = (
    <div className={`${classes.loaderContainer} app-loading-element__progress ${fullscreen ? classes.fullscreen : ''}`}>
      <CircularProgress size={32} thickness={5} />
    </div>
  )

  if (!children) {
    return loader
  }

  return (
    <div className={`${classes.root} ${loading ? classes.loading : ''} ${className || ''} app-loading-element__container`} {...rest}>
      {children}
      {loading && loader}
    </div>
  )
}

export default LoadingElement;