import React from 'react';

export const OurValues = () => {
  return (
    <section className="about-section-4">
      <div className="container">
        <div>
          <h2>Core Values</h2>
          <ul style={{ paddingLeft: '10px' }}>
            <li>We strive to be <span className="blue" style={{fontWeight:"400"}}>the best</span> at what we do</li>
            <li> We play as a <span className="blue" style={{fontWeight:"400"}}>team</span></li>
            <li> We are unafraid to<span className="blue" style={{fontWeight:"400"}}> admit mistakes</span> </li>
            <li> We listen, we service,<span className="blue" style={{fontWeight:"400"}}> because we care</span></li>
            <li><span className="blue" style={{fontWeight:"400"}}>We are passionate</span> about whatwe do </li>
            <li><span className="blue" style={{fontWeight:"400"}}>We do whatever it takes to</span> deliver on our promises</li>
            <li>We strive to always <span className="blue" style={{fontWeight:"400"}}>deliver on time</span></li>
          </ul>
          <a
            href="/careers"
            className="blue-btn"
            rel="noopener noreferrer"
          >
            APPLY FOR JOB!
          </a>
        </div>
      </div>
    </section>
  )
}