import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BlogImg1 from "../Assets/images/news/agm-designline-article.jpg";
import BlogImg2 from "../Assets/images/news/agm-city-ottawa-interview.png";
import BlogImg3 from "../Assets/images/news/680News16x9.jpg";

export default class SwipeToSlide extends Component {
  render() {

    const blogs = [
        {
            index_key: 1,
            image: BlogImg2,
            date: "Wednesday May 26, 2021",
            title: "Ivan Atanasov(CEO AGM) live interview on CTV",
            blog_body: "Proud of our growth in the industry, culminating in professional level renovations featured across Canada. Check out our CTV Live Interview.",
            link: "https://ottawa.ctvnews.ca/video?clipId=2208617"
        },
        {
            index_key: 2,
            image: BlogImg1,
            date: "Monday May 17, 2021",
            title: "AGM Featured in Design Line Magazine",
            blog_body: "How the leading renovation company reimagines renovations – and launches careers By Designlines in partnership with AGM Renovations",
            link: "https://www.designlinesmagazine.com/agm-basements-transforms-gta-homes/"
        },
        {
          index_key: 3,
          image: BlogImg3,
          date: "Friday June 04, 2021",
          title: "AGM Bathroom Renovations",
          blog_body: "If you bathroom is over 10 years old, it's time to renovate!",
          link: "https://bathroomrenovations.com/audio/AGM-Bathroom-680-News.mp3"
      },
    ];

    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1525,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
      ],
      afterChange: function (index) {
        console.log(
          `Slider Changed to: ${index + 1}`
        );
      },
    };
    return (
        
      <div className="stationSlider news">
        <h4>
          {" "}
          RECENT NEWS AND EVENTS
        </h4>
        <Slider {...settings}>
            {blogs.map((blog,idx) => (
                <div key={idx} className={'blog-item'}>
                    <div className="cnt-blog-left">
                        <img src={blog.image} alt="" className={'blog-image float-right'} />
                        <h2>{blog.title}</h2>
                            <span>{blog.date}</span><br/>
                        <a href={blog.link} className="blue-btn" target="_blank">
                        Click to view
                        </a>
                    </div>
                </div>
            ))}         
        </Slider>
      </div>
    );
  }
}
