import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions() {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={`accordion ${expanded === 'panel1' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee', }}>
						Light doesn't work?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li className="descriotion">Please change the
							light bulb
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={`accordion ${expanded === 'panel2' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }} >
						Door
						stopped
						closing properly?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Check
							humidity levels. If humidity levels are not above
							40-50%, please contact a technician to service your
							furnace and allow a week for your humidity levels to go
							back to normal
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className={`accordion ${expanded === 'panel3' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						I
						see cracks
						on the wall?

					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Check
							humidity levels. If humidity levels are not above
							40-50%, please contact a technician to service your
							furnace and allow a week for your humidity levels to go
							back to normal
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className={`accordion ${expanded === 'panel4' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						How
						to wash
						laminate flooring
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li className="descriotion">
							½ bucket of water and 100gr of vinegar, use a mob to
							spread the mixture but make sure the mop is slightly
							damped by squeezing out as much liquid or else it would
							damage the floor
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className={`accordion ${expanded === 'panel5' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						Granite
						countertop maintenance?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Don't
							use a sponge, it could scratch the surface.
						</li>
						<li>Remove
							any liquids such as ketchup, coffee, oil, immediately
							because natural stone countertops are very corase and
							can be easily stained
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className={`accordion ${expanded === 'panel6' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						Power
						outlets?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Do
							not use very high voltage items such as heaters or
							powerful fans or the plug will burn and the lights in
							the basement will start flickering. Please use the
							kitchen or batheroom outlets as they are specially
							installed for high voltage appliances
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className={`accordion ${expanded === 'panel7' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						Wall
						or floor damage by client moving furnature in the basement?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Not
							covered by our warranty
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className={`accordion ${expanded === 'panel8' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						Furnace
						stopped working?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Change
							the filter
						</li>
						<li>Check furnace room switch
							if it is on
						</li>
						<li>Check if all electrical
							panel barkers are on
						</li>
					</ul>
					<b>
						Furnace
						filter to be changed every week
					</b>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className={`accordion ${expanded === 'panel9' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						No
						power
						upstair?
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Check
							if all electrical panel barkers are on</li>
					</ul>
					<b>
						Furnace
						filter to be changed every week
					</b>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className={`accordion ${expanded === 'panel10' ? 'active' : ''}`}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="summary"
				>
					<Typography sx={{ width: '100%', flexShrink: 0, textTransform: "uppercase", color: '#45bfee' }}>
						In
						case of
						kitchen installation
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						<li>Client
							MUST bring appliances downstairs NOT AGM
						</li>
					</ul>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
