import React from 'react';

import { 
    OurNewsBlog
} from '../../Containers/OurNews'

export const OurNews = (props) => {

  return (
    <>
      <OurNewsBlog />
    </>
  );
}