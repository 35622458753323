import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';

import ReactPlayer from 'react-player';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles, styled } from '@material-ui/core/styles';
// import CheckIcon from '@material-ui/icons/Check';

const MyButton = styled(Button)({
  background: 'linear-gradient(to left, #1d3c5a 0%, #1195bb 100%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(69, 188, 237, .3)',
  color: 'white',
  height: '33px',
  opacity: '0.8',
  padding: '0 30px',
  '&:hover': {
    opacity: '1'
  },
//   maxWidth: '250px'
});

const useStyles = makeStyles({
  rootD: {
    ['& .MuiPaper-root']: {
        overflow: 'visible',
    }
  },
  root: {
    padding: '32px 20px',
    height: 'auto',
  },
  title: {
    '& h2': {
      fontFamily: "Josefin Sans",
      userSelect: 'none',
      textAlign: 'center',
      textTransform: 'uppercase',
      paddingTop: '20px',
      fontSize: '1.05rem',
    }
  },
  message: {
    fontFamily: "Quicksand",
    userSelect: 'none',
  },
  messageStart: {
    fontFamily: "Quicksand",
    userSelect: 'none',
    textAlign: 'center',
  },
  button: {
    width: '50%',
  },
  customIconCloseChat: {
    color: '#19ace4',
    background: '#fff',
    borderRadius: '50%',
      cursor: "pointer",
      position: "absolute",
      right: '2px',
      top: "2px",
      width: '38px',
      height: '38px',
      margin: '-22px -22px 0 0',
  },
});


export default function VideoModal({ isOpen, videoId, handleClose }) {
 
const classes = useStyles();

  return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        className={classes.rootD}
        aria-labelledby="customized-dialog-title"
      >
        <DialogContent className={classes.root} >
            <div className="videoContainer">
                   <ReactPlayer
                    controls={true}
                    loop={true}
                    rel={0}
                    playing={true}
                    autoPlay={true}
                    width="inherit"
                    url={`https://www.youtube.com/watch?v=${videoId}`}
                  />
            </div>
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={handleClose}/>

        </Dialog>
  );
}
