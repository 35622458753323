import React from 'react';

let region = '';

export const HowItWorks = ({ items = [] }) => {

  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  if(region==='calgary' || region==='vancouver' ){
    return (  
    <section className="home-hiw">
      <div className="container">
        <h2>How It Works</h2>
        <ul>
          {items.map((item, index) => (
            <li key={index} data-number={index + 1}>
              {!item.useBackgroundImage && (
                <div>
                  <img alt='' {...item.imgProps} />
                </div>
              )}
              <h3>
                {item.useBackgroundImage && <span style={{ backgroundImage: `url(${item.imgProps.src})` }} className="item-image" />}
                {item.title}
              </h3>
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
} else {   
    return (
      <section className="home-hiw">
        <div className="container">
          <h2>How It Works</h2>
          <ul>
            {items.map((item, index) => (
              <li key={index} data-number={index + 1}>
                {!item.useBackgroundImage && (
                  <div>
                    <img alt='' {...item.imgProps} />
                  </div>
                )}
                <h3>
                  {item.useBackgroundImage && <span style={{ backgroundImage: `url(${item.imgProps.src})` }} className="item-image" />}
                  {item.title}
                </h3>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }
}
