import React, { useState, Fragment, useEffect } from "react";

import "./realEstate.scss";
import { loadSpreadsheetPage } from "../../Services/sheets";
import InputChecked from "./InputChecked";
import { Modal } from "../../Common/Modal";
import PersonModal from "./PersonModal";
import AgentImg from "../AgentImg";
import Loading from "../Loading";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import testImg from "../../Assets/images/test-img-real.png";

const RealEstate = () => {
  const [loader, setLoader] = useState(false);
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [agentType, setAgentType] = useState([]);
  const [lastActivity, setLastActivity] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterActive, setfilterActive] = useState({});
  const [imgContainer, setImgContainer] = useState([]);
  const [sliceTo, setSliceTo] = useState({
    locations: 5,
    languages: 5,
    agentType: 5,
  });
  const [modal, setModal] = useState(false);
  const [personInfoModal, setPersinModalInfo] = useState(null);

  const mobile = window.innerWidth < 650 ? true : false;

  const handleShowMore = (value) => {
    let slice = Object.assign({}, sliceTo);
    slice[value] = slice[value] == undefined ? 5 : undefined;
    setSliceTo(slice);
  };

  const getSheetsData = async () => {

    const data = await loadSpreadsheetPage();
    const dataWithImg = data.items.map((item) => {
      const photo = <AgentImg photoId={item.photoId} />;
      item.img = photo;
      return item;
    });
    setItems(dataWithImg);
    setFilterData(dataWithImg);
    // setItems(data.items);
    // setFilterData(data.items);
    setLocations(data.locations);

    setLanguages(data.languages);
    setAgentType(data.agentType);
    setLastActivity(data.lastActivity);
    setLoader(true);
  };

  useEffect(() => {
    getSheetsData();
  }, []);

  const handleOnChange = () => {
    let handleFiterAray = items;
    if (filterActive.locations) {
      handleFiterAray = handleFiterAray.filter((el) => {
        return filterActive.locations.includes(el.locations);
      });

      setFilterData(handleFiterAray);
    } else {
      setFilterData(handleFiterAray);
    }
    if (filterActive.languages) {
      handleFiterAray = handleFiterAray.filter((el) => {
        let langArray = el.languages.split(", ");
        return langArray.some((element) => filterActive.languages.includes(element));
      });
      setFilterData(handleFiterAray);
    } else {
      setFilterData(handleFiterAray);
    }

    if (filterActive.agentType) {
      handleFiterAray = handleFiterAray.filter((el) => {
        let res = false;
        let agentTypeArray = el.agentType.split("/");
  
        filterActive.agentType.forEach((filterValue) => {
          // agent = agent.split(" ");
          // let filterValue = agent[agent.length - 1];
          agentTypeArray.forEach((element) => {
            if (!res) res = filterValue == element;
          });
        });
        return res;
      });
      setFilterData(handleFiterAray);
    } else {
      setFilterData(handleFiterAray);
    }

    if (filterActive.lastActivity) {
      if (filterActive.lastActivity == "Anytime") {
        setFilterData(handleFiterAray);
      } else {
        handleFiterAray = handleFiterAray.filter((el) => {
          return filterActive.lastActivity.includes(el.lastActivity);
        });
        setFilterData(handleFiterAray);
      }
    } else {
      setFilterData(handleFiterAray);
    }
  };

  const itemHandler = (valueKey, sortItem) => {
   
    if (typeof filterActive[valueKey] !== "undefined") {
   
      if (filterActive[valueKey].includes(`${sortItem}`)) {
        const filterData = Object.assign({}, filterActive);
        filterData[valueKey] = filterActive[valueKey].filter(
          (item) => item !== sortItem
        );
        if (filterData[valueKey] == false) {
          delete filterData[valueKey];
        }
        if (valueKey === "lastActivity") {
          if (sortItem !== undefined) {
            filterData[valueKey] = sortItem;
          }
        }
        setfilterActive(filterData);
      } else {
        let newData = filterActive[valueKey];
        if (valueKey === "lastActivity") {
          if (sortItem !== undefined) {
            newData = [`${sortItem}`];
            setfilterActive((state) => {
              return { ...state, [valueKey]: newData };
            });
          }
        } else {
          newData.push(`${sortItem}`);
          setfilterActive((state) => {
            return { ...state, [valueKey]: newData };
          });
        }
      }
    } else {
      setfilterActive((state) => {
        return { ...state, [valueKey]: [`${sortItem}`] };
      });
    }

    if (Object.keys(filterActive).length == 0) {
      setFilterData(items);
    }

  };

  useEffect(() => {
    handleOnChange();
  }, [filterActive]);

  return (
    <div className="wrapperRealEstate">
      <Loading isReady={loader} />
      {!!personInfoModal && (
        <PersonModal
          open={modal}
          onClose={() => setModal(false)}
          person={personInfoModal}
        >
          {personInfoModal.img}
        </PersonModal>
      )}

      <div className="sideBar">
        <div className="filter">
          <h3>LOCATIONS</h3>
          <div className="scrollContainer">
            {locations.map((location, index) => (
              <InputChecked
                key={index}
                handler={itemHandler}
                sorValue={"locations"}
                item={location}
              />
            ))}
            {/* <button
            onClick={() => handleShowMore("locations")}
            style={{
              border: "none",
              backgroundColor: "white",
              color: "grey",
            }}
          >
            {sliceTo.locations === undefined
              ? "Show less ..."
              : "Show more ..."}
          </button> */}
          </div>
        </div>
        <div className="filter">
          <h3>LANGUAGES</h3>
          <div className="scrollContainer">
            {languages.map((language, index) => (
              <InputChecked
                key={index}
                item={language}
                handler={itemHandler}
                sorValue={"languages"}
              />
            ))}
          </div>
        </div>
        <div className="filter">
          <h3>AGENT TYPE</h3>
          <div className="scrollContainer">
            {agentType.map((type, index) => (
              <InputChecked
                key={index}
                item={type}
                handler={itemHandler}
                sorValue={"agentType"}
              />
            ))}
          </div>
        </div>
        <div className="filter">
          <h3>LAST ACTIVITY</h3>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Activity"
            name="radio-buttons-group"
          >
            {lastActivity.map((activity, index) => (
              <FormControlLabel
                key={index}
                value={activity}
                control={<Radio />}
                label={activity}
                onClick={(e) => itemHandler("lastActivity", e.target.value)}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: mobile ? "small" : "large",
                  },
                  "& .MuiFormControlLabel-label": {
                    color: "#1e3c56",
                    fontSize: mobile ? "small" : "large",
                    fontWeight: 400,
                  },
                }}
              />
            ))}
          </RadioGroup>
        </div>
      </div>

      <div className="content">
        <h2>REAL ESTATE DIRECTORY</h2>
        <div className="items">
          {filterData.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className="person"
                  onClick={(e) => {
                    setPersinModalInfo(item);
                    setModal(true);
                  }}
                >
                  {item.img}

                  <div>
                    <h4>{item.name}</h4>
                    <h4>{item.agentType}</h4>
                    <p>{item.countProjects} Properties in 2022</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RealEstate;
